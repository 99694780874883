/* @import './color.css';
@import './typography.css'; */
//@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&family=Prompt:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  --primary: #fc6521;
  --secondary: #171616;

  --error: #f05c5c;
  --warning: #f1820f;
  --success: #2ecc71;

  --white: #ffffff;
  --black: #313744;

  --gray: #434645;
  --light-gray: #d7d7d7;
  --dark-gray: #a7a7a7;
  --gray-blue: #c4d7e2;
  --barely-blue: #dde0df;
  --silver: #b1b1b1;
  --whisper: #eaeaea;
}

:is(html body #root) {
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
  height: 100vh;
  width: 100vw;
  // min-width: 1280px;
}

body {
  margin: 0;
  padding: 0;
  // min-width: 1280px;
}

.ant-dropdown-menu {
  font-family: 'Prompt', sans-serif;
  width: 150px;
  font-weight: 500;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  padding: 0 !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) !important;

  .ant-dropdown-menu-item:first-child:last-child:hover {
    border-radius: 20px 20px 20px 20px;
  }

  .ant-dropdown-menu-item:first-child:hover {
    border-radius: 20px 20px 0 0;
  }

  .ant-dropdown-menu-item:last-child:hover {
    border-radius: 0 0 20px 20px;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    transform: 0 !important;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    justify-content: flex-start;
    padding: 8px 12px;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.tooltip-action {
  padding-top: 0 !important;

  .ant-tooltip-inner {
    padding: 2px 8px;
    background-color: #597081;
    box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
    border-radius: 8px;
    min-height: 20px;
    min-width: 20px;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Prompt', sans-serif;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.gap {
  padding-left: 50px !important;
  justify-items: center !important;
}

.gap-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-items: center !important;
}

.gap-30 {
  padding-left: 30px !important;
  justify-items: center !important;
}

.gap-40 {
  padding-left: 40px !important;
  justify-items: center !important;
}

.gap-50 {
  padding-left: 50px !important;
  justify-items: center !important;
}

.sider-tooltip {
  padding-left: 0 !important;
  max-width: 500px !important;
  padding-top: 5px !important;

  .ant-tooltip-inner {
    border-radius: 16px !important;
    padding: 12px 11px !important;
    font-family: 'Prompt', sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: #597081;
    box-shadow: 0px 2px 16px #e2e5f8;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 31, 52, 0.5) !important;
  backdrop-filter: blur(8px);
}

.red-star {
  color: #f85858;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.export-report-notification {
  background-color: #dff6f5 !important;
  box-shadow: 0px 4px 16px 0px #c8d3da4d !important;
  width: 652px !important;
  border-radius: 12px !important;

  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  .ant-notification-notice-icon {
    top: 12px !important;
    margin-left: 0px !important;
    margin-right: 5px;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: 52px !important;
  }
}

.drawer-profile {
  .ant-drawer-content-wrapper {
    width: 600px !important;

    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.select-mobile {
  .ant-drawer-content-wrapper {
    height: 90% !important;
  }
}

.input-edit-profile-mobile {
  .ant-input {
    height: 32px;
  }
}

.input-edit-profile {
  .ant-input {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Prompt';
    padding-bottom: 22px;
    border: none !important;
    height: 20px;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  &:hover,
  &:focus,
  &:active {
    border: none !important;
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    border: none !important;
    box-shadow: none !important;
  }
}
